.breadcrumbs {
    margin-bottom: 44px;
    font-size: 14px;
    &__list {
        justify-content: flex-end;
        list-style: none;
    }
    &__current,
    &__link {
        color: rgb(var(--matterhorn));
    }
    &__link {
        text-decoration: underline;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    &__current {
        color: rgb(var(--mandy));
    }
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-bottom: 30px;
    }
}