.header {
    position: relative;
    z-index: 3;
    margin-top: 60px;
    margin-bottom: 18px;
    &__container {
        padding-right: 0;
        padding-left: 0;
    }
    &__top {
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 18px;
    }
    &__logo {
        .logo {
            &__img {
                max-width: 160px;
            }
            &__title {
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
    &__burger {
        display: none;
    }
}

@media (max-width: 992px) {
    .header {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .header {
        margin-top: 15px;
        &__top {
            justify-content: flex-start;
            margin-bottom: 0;
        }
        &__logo {
            margin-right: auto;
            .logo {
                &__img {
                    margin-right: 0;
                }
                &__title {
                    display: none;
                }
            }
        }
        &__bottom {
            position: fixed;
            top: 0;
            left: -270px;
            width: 270px;
            height: 100%;
            background-color: rgb(var(--eclipse));
            transition: transform .35s ease-out;
            overflow: auto;
            &.active {
                transform: translateX(270px);
            }
        }
        &__callback {
            margin-right: 15px;
        }
        &__burger {
            display: block;
        }
    }
}

@media (max-width: 420px) {
    .header {
        &__logo {
            .logo {
                &__img {
                    max-width: 120px;
                }
            }
        }
    }
}