.certification {
    margin-bottom: 20px;
    &__title {
        margin-bottom: 18px;
        font-weight: 800;
    }
    &__column,
    &__row {
        margin-bottom: 20px;
        line-height: 20px;
    }
    &__column {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .certification {
        &__column {
            width: 100%;
        }
    }
}