.overlay {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,.4);
        transition: background-color var(--transitionDefault);
    }
    &:hover,
    &:focus {
        &::after {
            background-color: transparent;
        }
    }
}