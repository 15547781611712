.callback {
    &__img {
        flex-shrink: 0;
        margin-right: 10px;
    }
    &__title {
        font-size: 17px;
        font-weight: 300;
        color: rgb(var(--eclipse));
    }
    &__phone {
        font-size: 27px;
        font-weight: 800;
        line-height: 25px;
        color: rgb(var(--fireEngineRed));
    }
}

@media (max-width: 575px) {
    .callback {
        &__img {
            margin-right: 0;
        }
        &__info {
            display: none;
        }
    }
}