.field {
    width: 100%;
    &__input,
    &__textarea {
        width: 100%;
        padding-right: 23px;
        padding-left: 23px;
        font-weight: 300;
        border: 1px solid rgb(var(--azalea));
        border-radius: 3px;
        &::placeholder {
            color: rgb(var(--pinkSwan));
        }
    }
    &__input {
        height: 36px;
    }
    &__textarea {
        height: 90px;
        padding-top: 18px;
        padding-bottom: 18px;
    }
}