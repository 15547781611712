footer {
    margin-top: auto;
}

.footer {
    &__container {
        padding-right: 0;
        padding-left: 0;
    }
    &__column {
        padding: 60px 18px;
        &:first-child {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 103px;
                height: 100%;
                background: url("../assets/img/footer.jpg") center no-repeat;
                background-size: cover;
            }
        }
        &:not(:nth-child(2)) {
            width: 34%;
            background-color: rgb(var(--dimGray));
        }
        &:nth-child(2) {
            width: 32%;
        }
    }
    &__nav {
        list-style: none;
        &-item {
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
        &-link {
            display: inline-block;
            font-size: 14px;
            line-height: 16px;
            color: #fff;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
            &.active {
                color: rgb(var(--fireEngineRed));
            }
        }
    }
    &__logo {
        margin-top: 60px;
        .logo {
            &__img {
                max-width: 124px;
            }
            &__title {
                font-size: 11px;
            }
        }
    }
    &__info,
    &__copyright {
        text-align: right;
        color: #fff;
    }
    &__info {
        margin-bottom: 120px;
        font-size: 14px;
        line-height: 16px;
        p {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        a {
            color: inherit;
        }
    }
    &__copyright {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
    }
}

@media (max-width: 992px) {
    .footer {
        &__column {
            &:first-child,
            &:nth-child(2) {
                width: 24%;
            }
            &:last-child {
                width: 52%;
            }
            &:first-child {
                &::after {
                    display: none;
                }
            }
        }
        &__logo {
            .logo {
                flex-direction: column;
                &__img {
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }
        }
        &__info {
            margin-bottom: 80px;
        }
    }
}

@media (max-width: 767px) {
    .footer {
        &__inner {
            flex-wrap: wrap;
        }
        &__column {
            padding-top: 22px;
            padding-bottom: 22px;
            &:first-child {
                width: calc(100% - 180px);
            }
            &:nth-child(2) {
                justify-content: center;
                width: 180px;
            }
            &:last-child {
                width: 100%;
            }
        }
        &__logo {
            margin-top: 0;
        }
        &__info,
        &__copyright {
            text-align: left;
        }
        &__info {
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 420px) {
    .footer {
        &__column {
            &:first-child {
                width: 100%;
            }
            &:nth-child(2) {
                order: -1;
                width: 100%;
            }
        }
        &__logo {
            .logo {
                flex-direction: row;
                &__img {
                    margin-right: 12px;
                    margin-bottom: 0;
                }
            }
        }
    }
}