.typography {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 16px;
        font-family: inherit;
        font-weight: 800;
        line-height: 1.2;
        color: rgb(var(--matterhorn));
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 28px;
    }

    h4 {
        font-size: 24px;
    }

    h5 {
        font-size: 20px;
    }
    
    h6 {
        font-size: 16px;
    }

    p {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px;
    }

    a {
        display: inline-block;
        color: #007bff;
    }

    mark {
        padding: .2em;
        background-color: #fcf8e3;
    }

    small {
        font-size: 80%;
        font-weight: 400;
    }

    b, strong {
        font-weight: 800;
    }

    blockquote {
        margin: 0 0 16px;
    }

    dl, 
    ol, 
    ul {
        margin-top: 0;
        margin-bottom: 16px;
    }

    ol ol, 
    ol ul, 
    ul ol, 
    ul ul {
        margin-bottom: 0;
    }

    ul {
        list-style: none;
        li {
            position: relative;
            padding-left: 8px;
            font-size: 14px;
            line-height: 20px;
            &::before {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                width: 4px;
                height: 4px;
                background-color: rgb(var(--fireEngineRed));
                border-radius: 50%;
            }
        }
    }

    table {
        border-collapse: collapse;
        border: 1px solid #ddd;
    }
    table th,
    table td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd;
    }
}