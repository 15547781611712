.request {
    position: fixed;
    top: 15%;
    right: 0;
    z-index: 4;
    transform: translateX(100%);
    transition: transform .4s ease-out;
    &.active {
        transform: translateX(0);
    }
    &__control {
        position: absolute;
        right: 100%;
        &-btn {
            width: 100%;
            height: 100%;
            padding: 18px 15px;
            text-transform: uppercase;
            color: #fff;
            background-color: rgb(var(--fireEngineRed));
            border-radius: 0 3px 3px 0;
            transform: rotate(180deg);
            span {
                writing-mode: vertical-rl;
            }
        }
    }
    &__form {
        width: 485px;
        padding: 34px 46px;
        background-color: #fff;
        border: 1px solid rgb(var(--fireEngineRed));
        &-title {
            margin-bottom: 24px;
            font-size: 24px;
            font-weight: 900;
            text-transform: uppercase;
            color: rgb(var(--fireEngineRed));
        }
        &-subtitle {
            margin-bottom: 30px;
            font-size: 14px;
            line-height: 18px;
            color: rgb(var(--nightRider));
        }
        &-fields {
            margin-bottom: 18px;
        }
        &-field {
            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }
        &-submit {
            max-width: 134px;
        }
    }
}

@media (max-width: 575px) {
    .request {
        display: none;
    }
}