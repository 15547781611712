:root {
    /* Colors */
    --mainColor: 65,65,65;
    --eclipse: 56,56,56;
    --fireEngineRed: 202,16,26;
    --grey: 122,122,122;
    --dimGray: 102,102,102;
    --nightRider: 50,50,50;
    --pinkSwan: 191,178,178;
    --azalea: 248,189,191;
    --charCoal: 71,71,71;
    --mandy: 218,80,80;
    --matterhorn: 75,75,75;
    --gainsboro: 221,221,221;
    --sunDown: 241,174,174;
    --tutu: 252,230,230;

    /* Fonts */
    --mainFont: "OpenSans", sans-serif;

    /* Transition */
    --transitionDefault: .2s ease-out;
}