.burger {
    display: block;
    margin-left: auto;
    &-inner {
        justify-content: space-between;
        align-items: flex-end;
        width: 30px;
        height: 19px;
    }
    &-stroke {
        width: 100%;
        height: 2px;
        background-color: rgb(var(--mainColor));
        &:first-child,
        &:nth-child(2) {
            transform-origin: right;
            transition: transform .2s ease-out;
        }
        &:first-child {
            transform: scaleX(.33);
        }
        &:nth-child(2) {
            transform: scaleX(.5);
        }
    }
    &:hover {
        .burger {
            &-stroke {
                &:first-child {
                    transform: scaleX(.5);
                }
                &:nth-child(2) {
                    transform: scaleX(.75);
                }
            }
        }
    }
    &.active {
        .burger {
            &-stroke {
                &:first-child,
                &:nth-child(2) {
                    transform: scaleX(1);
                }
            }
        }
    }
}