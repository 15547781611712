.nav {
    &__list {
        list-style: none;
    }
    &__item {
        position: relative;
        flex: 1;
        &:not(:last-child) {
            &::after {
                content: "";
                position: absolute;
                top: 4px;
                right: -.5px;
                z-index: 2;
                width: 1px;
                height: calc(100% - 8px);
                background-color: rgb(var(--grey));
            }
        }
    }
    &__link {
        padding: 8px;
        font-size: 15px;
        line-height: 14px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        background-color: rgb(var(--eclipse));
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        &:hover,
        &:focus {
            border-bottom-color: rgb(var(--fireEngineRed));
        }
        &.active {
            background-color: rgb(var(--fireEngineRed));
        }
    }
    &Menu,
    &Submenu {
        position: absolute;
        display: none;
        &::before {
            content: "";
            position: absolute;
        }
    }
    &Menu {
        top: 100%;
        left: 0;
        width: 248px;
        padding-top: 10px;
        &::before {
            top: 3px;
            left: 15px;
            border-left: 7px solid transparent;
            border-bottom: 7px solid rgb(var(--eclipse));
            border-right: 7px solid transparent;
        }
        &__list {
            list-style: none;
            background-color: rgb(var(--eclipse));
        }
        &__item {
            position: relative;
            &::before,
            &::after {
                position: absolute;
                left: 12px;
                width: calc(100% - 24px);
                height: 1px;
                background-color: rgb(var(--dimGray));
            }
            &:not(:first-child) {
                &::before {
                    content: "";
                    top: -.5px;
                }
            }
            &:not(:last-child) {
                &::after {
                    content: "";
                    bottom: -.5px;
                }
            }
        }
        &__link {
            padding: 9px 18px;
            font-size: 13px;
            line-height: 14px;
            color: #fff;
            &:hover,
            &:focus {
                background-color: rgba(255,255,255,.13);
            }
        }
    }
    &Submenu {
        top: 0;
        left: 100%;
        width: 214px;
        padding-left: 12px;
        &::before {
            top: 12px;
            left: 5px;
            border-top: 7px solid transparent;
            border-right: 7px solid rgb(var(--eclipse));
            border-bottom: 7px solid transparent;
        }
    }
}

@media (hover: hover) {
    .nav {
        &__item {
            &:hover {
                .navMenu {
                    display: block;
                }
            }
        }
        &Menu {
            &__item {
                &:hover {
                    .navSubmenu {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .nav {
        &__list {
            flex-direction: column;
        }
        &__item {
            &:not(:last-child) {
                &::after {
                    display: none;
                }
            }
        }
        &__link {
            padding: 12px;
            font-size: 18px;
            border-right: 2px solid transparent;
            border-top: none;
            border-bottom: none;
            &:hover,
            &:focus {
                border-right-color: rgb(var(--fireEngineRed));
            }
        }
        &Menu,
        &Submenu {
            position: static;
            display: block;
            width: 100%;
            &::before {
                display: none;
            }
        }
        &Menu {
            padding-top: 0;
        }
        &Submenu {
            padding-right: 12px;
        }
    }
}