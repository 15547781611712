.btn {
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    background-color: rgb(var(--fireEngineRed));
    border-radius: 3px;
    box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
    &:not(.flexRow-C) {
        display: block;
    }
}