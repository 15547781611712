@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-Light.woff2") format("woff2"),
    url("../assets/fonts/OpenSans/OpenSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/OpenSans/OpenSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/OpenSans/OpenSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/OpenSans/OpenSans-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/OpenSans/OpenSans-ExtraBold.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}